import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Contact from "../components/Contact"

const contact = ({ location }) => {
  //console.log("contact location=", location)
  return (
    <Layout location={location}>
      <SEO title="Contact" description="contact Kim and Hwang" />
      <Contact />
    </Layout>
  )
}

export default contact
