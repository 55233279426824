import React from "react"
import styled from "styled-components"

const Contact = () => {
  //const handleSubmit = e => {
  //  e.preventDefault()
  //  console.log("Contact Form summit clicked")
  //}

  return (
    <Wrapper>
      <section className="contact-page">
        <article className="contact-form">
          <h3>문의 서비스를 준비 중입니다</h3>
          <h4>문의는 kimhwangdata&#64;gmail.com 로 해주세요</h4>
          {/*<form>
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="name"
                className="form-control"
              />
              <input
                type="email"
                name="email"
                placeholder="email"
                className="form-control"
              />
              <textarea
                name="message"
                rows="5"
                placeholder="message"
                className="form-control"
              />
            </div>
            <button
              type="submit"
              className="submit-btn btn"
              onClick={handleSubmit}
            >
              submit here
            </button>
          </form>*/}
        </article>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .contact-page {
    background: var(--clr-grey-10);
    min-height: calc(100vh - 5rem - 9rem);
  }
  .contact-page {
    display: grid;
    place-items: center;
    padding: 5rem 0;
  }
  .contact-form {
    background: var(--clr-white);
    border-radius: var(--radius);
    text-align: center;
    box-shadow: var(--light-shadow);
    transition: var(--transition);
    width: 90vw;
    max-width: 35rem;
  }
  .contact-form:hover {
    box-shadow: var(--dark-shadow);
  }
  .contact-form h3 {
    padding-top: 1.25rem;
    color: var(--clr-grey-5);
  }
  .form-group {
    padding: 1rem 1.5rem;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    border: none;
    margin-bottom: 1.25rem;
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    text-transform: uppercase;
    letter-spacing: var(--spacing);
  }
  .form-control::placeholder {
    font-family: var(--ff-primary);
    color: var(--clr-grey-1);
    text-transform: uppercase;
    letter-spacing: var(--spacing);
  }
  .submit-btn {
    display: block;
    width: 100%;
    padding: 1rem;
    border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`

export default Contact
